<script setup>
import {
  computed,
  defineAsyncComponent,
  onBeforeMount,
  onBeforeUnmount,
  toRef,
  watch,
  nextTick,
  ref,
} from 'vue';
import { isBoolean, orderBy } from 'lodash-es';
import { SpecifierTypesCache } from '@nsftx/sports-client-aio-display-name-sdk';

import { useStore } from '@/stores/store';
import { useBetslipStore } from '@/stores/BetslipStore';

import { getPunterPreferences } from '@/api/punter-preferences';
import getLocalConfig from '@/api/local-config';
import getTranslations from '@/api/translations';

import {
  establishBetslipDistributionSocketConnection,
  disconnectFromBetslipDistributionSocketConnection,
} from '@/services/sockets/betslipDistributionSocket';
import { establishCalculationSocketConnection } from '@/services/sockets/calculationSocket';
import { initializeOfferDistributionWSConnection } from '@/services/sockets/offer-distribution';

import { isMobile } from '@/services/helpers';
import {
  getCursorOfferStats,
  getCursorPromoOfferStats,
  getMarketSpecifiers,
  getOffer,
  getPromotedOffer,
} from '@/api/distribution';
import { loadFavouritesOffer, fetchAndCalculateNumberOfFavouriteEvents } from '@/adapters/offer';
import { constructClientIds, constructEndDate, constructStartDate } from '@/utils/helpers';
import tenants from '@/utils/tenants';
import { getItemFromStorage, removeItemFromStorage, setItemToStorage } from '@/services/storage';
import { resetLocalState } from '@/services/offer-adapter';
import { establishCashoutSocketConnection } from '@/services/sockets/cashoutSocket';
import loadSportradarTheming from '@/utils/loadSRTheming.js';
import { sirConnect } from '@/services/sirWidgetLoader';
import createChildIntegratorInstance from '@/services/child-integrator.js';
import { initGoogleTagManager } from '@/services/googleTagManager';

const store = useStore();
const { setSelectedFiltersExternal } = store;
const betslipStore = useBetslipStore();

let initialLoad = true;

const isLive = computed(() => store.isLive);
const game = computed(() => (isLive.value ? 'LIVE' : 'SPORTS'));

/**
 * Retrieves local app config (default and per-tenant config, if tenant name provided) and stores it.
 * @async
 * @function loadAppConfig
 * @returns {Promise<Boolean>}
 */
async function loadAppConfig() {
  const tenantName =
    new URLSearchParams(document.location.search).get('tenantName') ??
    tenants[window.tenantUuid]?.toLowerCase();

  const cfg = await getLocalConfig(tenantName);
  store.setConfig(cfg);

  return true;
}

/**
 * Retrieves punter preferences from the server and stores them.
 * @async
 * @function applyPunterPreferences
 * @returns {Promise<void>} A promise that resolves when the punter preferences are retrieved and stored successfully.
 */
async function applyPunterPreferences() {
  const { config } = store;

  const localPunterPreferences = getItemFromStorage('punterPreferences') || {};
  const data = window.punterToken
    ? ((await getPunterPreferences(window.punter?.id)) ?? localPunterPreferences)
    : localPunterPreferences;

  const validatePreference = (key, validSet, set = config) => {
    if (data?.[key] && !set?.[validSet]?.has(data[key])) delete data[key];
  };

  validatePreference(
    'offerView',
    isMobile ? 'availableMobileOfferViews' : 'availableDesktopOfferViews',
  );
  validatePreference('oddsFormat', 'availableOddFormats');
  validatePreference('theme', 'availableThemes');
  validatePreference('oddsChangesOption', 'availableOddsChangesOptions', config?.betslip);

  store.punterPreferences = data;

  const localStorageTheme = getItemFromStorage('punter-theme');
  if (!localStorageTheme && data.theme) {
    setItemToStorage('punter-themes', data.theme);
  }

  setupTheming();
  if (!betslipStore.stake || betslipStore.stake === betslipStore.defaultStake)
    betslipStore.stake = store.punterPreferences?.defaultPayment ?? betslipStore.defaultStake;

  loadFavouritesData();
}

/**
 * Loads the theme based on the provided query parameters and sets the company name if available.
 * @function setupTheming
 * @returns {void}
 */
function setupTheming() {
  window.tenantName =
    new URLSearchParams(document.location.search).get('tenantName') ??
    tenants[window.tenantUuid]?.toLowerCase();

  const punterTheme = getItemFromStorage('punter-theme') ?? store.punterPreferences?.theme;
  const selectedTheme = window.theme || punterTheme;

  const theme = store.config.availableThemes?.has(selectedTheme)
    ? selectedTheme
    : store.config.defaultTheme;
  store.punterPreferences.theme = theme;

  document.documentElement.dataset.theme = theme;
  document.documentElement.dataset.company = tenantName || '';

  import(`@/assets/styles/themes/_${tenantName}.scss`).catch(() => {
    /* Do nothing */
  });

  loadSportradarTheming(theme);
}

/**
 * Loads offer statistics based on selected filters and updates the store with the fetched data.
 * @async
 * @function loadCursorOfferStats
 * @returns {Promise<void>} A promise that resolves when the offer statistics are successfully loaded and stored.
 */

async function loadCursorOfferStats() {
  try {
    const selectedFiltersExternal = store.selectedFiltersExternal;

    const params = {
      clientIds: constructClientIds(),
      ...(store.config?.supplemental?.list
        ? { supplementalNameTypeIds: store.config.supplemental.list }
        : {}),
    };
    if (selectedFiltersExternal?.time !== 'all') {
      const startDate =
        store.timeFilters[selectedFiltersExternal.time]?.date ?? constructStartDate();

      const endDate =
        store.timeFilters[selectedFiltersExternal.time]?.endDate ??
        constructEndDate(startDate) ??
        constructEndDate();

      params.startsAtFrom = startDate;
      params.startsAtTo = endDate;
    }

    if (isLive.value) params.streamContentTypes = [1];

    const response = await getCursorOfferStats(
      params,
      game.value === 'LIVE' ? '2' : selectedFiltersExternal.sortBy !== 'byTime' ? '1,2' : '1',
    );
    if (Object.values(store.filterSports).length) {
      store.resetFilterData();
    }
    store.setFilterData(response.data);

    if (response.data?.sportNumberOfStreamEvents)
      store.setNumberOfStreamedEvents(response.data.sportNumberOfStreamEvents);

    if (store.config.promotedOffer) {
      try {
        const promoResponse = await getCursorPromoOfferStats(
          params,
          game.value === 'LIVE' ? '2' : '1',
        );
        if (Object.values(store.promoFilterOffers).length) {
          store.resetPromoOfferFilterData();
        }
        store.setPromoOfferFilterData(promoResponse.data);
      } catch (error) {
        console.error(`We've encountered error loading promoted offer.`, error);
      }
    }
  } catch (error) {
    console.log(`We've encountered error loading offer statistics.`, error);
  }
}

/**
 * Loads favourites data (offer or stats).
 * @function loadFavouritesData
 * @returns {Promise<void>}
 */
function loadFavouritesData() {
  if (store.selectedFiltersExternal?.favourites) loadAndSubscribeOfferDataByFilters();
  else fetchAndCalculateNumberOfFavouriteEvents();
}

/**
 * Loads cursor offer based on selected filters and updates the store with the fetched data.
 * @async
 * @function loadOffer
 * @returns {Promise<void>} A promise that resolves when the cursor offer is successfully loaded and stored.
 */
async function loadOffer() {
  const selectedFiltersExternal = store.selectedFiltersExternal;
  store.setLoading(true);

  try {
    const params = {
      type: selectedFiltersExternal.sortBy === 'byTime' ? 2 : 1,
      clientIds: constructClientIds(),
      ...(store.config?.supplemental?.list
        ? { supplementalNameTypeIds: store.config.supplemental.list }
        : {}),
    };

    if (selectedFiltersExternal?.time !== 'all') {
      const startDate =
        store.timeFilters[selectedFiltersExternal.time]?.date ?? constructStartDate();

      const endDate =
        store.timeFilters[selectedFiltersExternal.time]?.endDate ??
        constructEndDate(startDate) ??
        constructEndDate();

      params.startsAtFrom = startDate;
      params.startsAtTo = endDate;
    }

    if (selectedFiltersExternal.streamedEvents) params.streamContentTypes = [1];

    if (selectedFiltersExternal.sport || routeParams.value.sport)
      params.sports =
        selectedFiltersExternal.sport?.id ??
        selectedFiltersExternal.sport ??
        routeParams.value.sport;

    if (selectedFiltersExternal.category || routeParams.value.category)
      params.categories =
        selectedFiltersExternal.category?.id ??
        selectedFiltersExternal.category ??
        routeParams.value.category;

    if (selectedFiltersExternal.tournament || routeParams.value.tournament)
      params.tournaments =
        selectedFiltersExternal.tournament?.id ??
        selectedFiltersExternal.tournament ??
        routeParams.value.tournament;

    if (selectedFiltersExternal.promotedOffer || routeParams.value.promotedOffer)
      params.promotedOffers =
        selectedFiltersExternal.promotedOffer?.id ??
        selectedFiltersExternal.promotedOffer ??
        routeParams.value.promotedOffer;

    if (selectedFiltersExternal.catalog || routeParams.value.catalog)
      params.promotedOfferCatalogs =
        selectedFiltersExternal.catalog?.id ??
        selectedFiltersExternal.catalog ??
        routeParams.value.catalog;

    setSelectedFiltersExternal({
      ...selectedFiltersExternal,
      ...(params.sports ? { sport: store.filterSports[params.sports] } : {}),
      ...(params.categories ? { category: store.filterCategories[params.categories] } : {}),
      ...(params.tournaments ? { tournament: store.filterTournaments[params.tournaments] } : {}),
      ...(params.promotedOffers
        ? {
            promotedOffer: store.promoFilterOffers[params.promotedOffers],
          }
        : {}),
      ...(params.promotedOfferCatalogs
        ? {
            catalog: store.promoFilterCatalogs[params.promotedOfferCatalogs],
            promotedOffer:
              store.promoFilterOffers[
                store.promoFilterCatalogs[params.promotedOfferCatalogs]?.promotedOfferId
              ],
          }
        : {}),
    });

    if (params.categories || params.tournaments) {
      store.expandedSport = params.tournaments
        ? store.filterCategories[store.filterTournaments[params.tournaments]?.categoryId]?.sportId
        : store.filterCategories[params.categories]?.sportId;
    }

    if (params.tournaments) {
      store.expandedCategory = store.filterTournaments[params.tournaments]?.categoryId;
    }

    if (
      initialLoad &&
      !selectedFiltersExternal?.streamedEvents &&
      !params?.sports &&
      !params?.categories &&
      !params?.tournaments &&
      !params?.promotedOffers &&
      !params?.promotedOfferCatalogs
    ) {
      const firstSport = orderBy(
        Object.values(store.filterSports).filter((sport) => sport.numberOfEvents),
        game.value === 'LIVE' ? 'positionLive' : 'positionPrematch',
      )?.[0];

      const setFirstSport = () => {
        if (!firstSport) return;

        params.sports = firstSport.id;
        setSelectedFiltersExternal({
          ...store.selectedFiltersExternal,
          sport: firstSport,
        });
      };

      switch (store.config?.defaultRoute[isLive.value ? 'live' : 'prematch'].offer) {
        case 'sport':
          if (!isLive.value) {
            setFirstSport();
          }
          break;
        case 'promo': {
          const firstOffer = orderBy(store.promoFilterOffers, 'position')?.[0];

          if (firstOffer) {
            params.promotedOffers = firstOffer.id;
            setSelectedFiltersExternal({
              ...store.selectedFiltersExternal,
              promotedOffer: firstOffer,
              id: null,
            });
            store.expandedPromoOffer = firstOffer.id;
          } else {
            if (!isLive.value) {
              setFirstSport();
            } else {
              setSelectedFiltersExternal({
                ...store.selectedFiltersExternal,
                id: 'all',
              });
            }
          }
        }
      }
    }

    if (routeParams.value.view !== 'search') {
      store.cursorId = null;

      let response = null;
      if (
        params.promotedOffers ||
        params.promotedOfferCatalogs ||
        (store.config?.defaultRoute[isLive.value ? 'live' : 'prematch'].offer === 'promo' &&
          initialLoad &&
          store.selectedFiltersExternal.promotedOffer)
      ) {
        if (params.promotedOffers && params.promotedOffers === 'all') {
          delete params.promotedOffers;
        }

        response = await getPromotedOffer(params, game.value === 'LIVE');
      } else {
        response = await getOffer(params, game.value === 'LIVE');
      }
      store.useOfferAdapter('INITIAL_OFFER_SETUP', response.data);
    }
    store.setLoading(false);
    return true;
  } catch (error) {
    console.log(`We've encountered error loading offer.`, error);
  }
}

/**
 * Fetches offer-related data based on selected filters and subscribes to offer distribution socket events.
 * @async
 * @function loadAndSubscribeOfferDataByFilters
 * @returns {Promise<void>} A promise that resolves when the offer data fetching and subscription are complete.
 */
async function loadAndSubscribeOfferDataByFilters() {
  store.unsubscribeEventsFromEventchanges();

  if (store.selectedFiltersExternal?.favourites) {
    await loadFavouritesOffer();
  } else await loadOffer();
}

/**
 * Reusable function which is used on application initial load, and during the game switch.
 * Function initializes Offer Distribution WS connection, resets previous filter and offer data and loads new one.
 * @async
 * @function loadInitialAppData
 * @returns {Promise<void>} A promise that resolves once necessary data is loaded.
 */
async function loadInitialAppData() {
  initializeOfferDistributionWSConnection(game.value === 'LIVE' ? '2' : '1,2', {
    ...(store.config?.supplemental?.list
      ? { supplementalNameTypeIds: store.config.supplemental.list }
      : {}),
    ...(store.config.promotedOffer ? { extensions: 'promoted-offer' } : {}),
  });

  await loadCursorOfferStats();
  await loadAndSubscribeOfferDataByFilters();

  if (!store.selectedFiltersExternal.favourites) fetchAndCalculateNumberOfFavouriteEvents();

  if (initialLoad) initialLoad = false;
}

/**
 * Fetches market specifiers used in Expresion language.
 * @async
 * @function loadMarketSpecifiers
 * @returns {Promise<void>} A promise that resolves once market specifiers are loaded and stored.
 */
async function loadMarketSpecifiers() {
  try {
    const response = await getMarketSpecifiers();
    new SpecifierTypesCache().setSpecifierTypes(response.data.types);
  } catch (error) {
    console.log(`We've encountered error loading market specifiers.`, error);
  }
}

let appReadyForLoad = ref(false);
async function loadApp() {
  store.currency = window.currency;
  store.setLoading(true);
  establishCalculationSocketConnection();

  await loadAppConfig();
  appReadyForLoad.value = true;

  let time = 'all';

  if (!isLive.value) {
    time = store.routeParams.time ?? store.config?.defaultRoute.prematch.time ?? 'all';
  }

  store.setSelectedFiltersExternal({
    ...store.selectedFiltersExternal,
    time,
  });

  applyPunterPreferences();

  loadInitialAppData();
  loadMarketSpecifiers();

  store.setTranslations(await getTranslations());

  store.createLiveEventsCountPeriodicUpdate();

  betslipStore.sendBetslipWorkerMessage('generate_pascal_triangle', {
    maxSelections: 30,
  });

  betslipStore.sendBetslipWorkerMessage('setup-limits-environment', {
    currency: window.currency,
    tenantUuid: window.tenantUuid,
  });

  const storedBet = getItemFromStorage('user-bet');

  if (storedBet) {
    betslipStore.updateStake(storedBet.stake);
    store.sendOfferWorkerMessage('load-stored-bet', {
      bet: storedBet,
      tenantUuid: window.tenantUuid,
      languageCode: window.languageCode,
      playerUuid: store.route.query?.playerUuid,
      ...(store.config?.supplemental?.list
        ? { supplementalNameTypeIds: store.config.supplemental.list }
        : {}),
    });
  }

  const { srWidgets = {} } = store.config;
  if (srWidgets?.liveMatchTracker?.enabled || srWidgets?.betAssist?.enabled) {
    sirConnect(store.config?.srWidgets?.url);
  }

  if (store.config.googleTagManager?.enabled) {
    initGoogleTagManager(store.config.googleTagManager.id);
  }

  try {
    await import(`../../node_modules/dayjs/locale/${window.languageCode}.js`);
    dayjs.locale(window.languageCode);
  } catch (e) {
    console.log(
      `An error occured while loading dayjs locale ${window.languageCode}, app will use en locale for date formats.`,
      e,
    );
  }

  // Notify parent and product is completely loaded.
  integrator.loaded();
}

emitter.on('load-app', (data) => loadApp(data));
onBeforeMount(() => {
  if (isBoolean(window.standalone)) {
    store.sendOfferWorkerMessage('set-worker-environment', {
      location: window.location,
    });
    loadApp();
  }
});

let shouldLoadPunterPreferences = false;
const integrator = createChildIntegratorInstance({
  onLoad: ({ context, settings, punter = {}, activeCurrencyBalance = 0 }) => {
    const urlParams = new URLSearchParams(window.location.search);
    const languageParam = urlParams.get('language');
    const localeParam = urlParams.get('locale');

    window.tenantUuid = context.tenantId;
    window.languageCode = settings.activeLocale ?? languageParam ?? localeParam ?? 'en';
    window.currency = settings.activeCurrency.code;
    window.oddsType = settings?.oddsOption?.toLowerCase() ?? '';
    window.manualWalletsUpdate = settings?.manualWalletsUpdate ?? false;

    if (punter?.identity?.id) {
      window.punter = { id: punter.identity.id };
      store.punterData = {
        nickname: punter.details?.nickname,
        id: punter.identity.id,
        type: punter.identity.type,
      };
    }

    if (window.punter?.id && +activeCurrencyBalance) {
      store.updatePunterWallet({
        enabled: true,
        activeCurrencyBalance: activeCurrencyBalance,
      });
    }

    loadApp();
  },

  onPunterDetailsUpdated: (data) => {
    const reloadAppData = data.id !== window?.punter?.id;

    window.punter = data;

    store.punterData = data;
    shouldLoadPunterPreferences = true;

    if (reloadAppData && !store.selectedFiltersExternal.favourites) {
      fetchAndCalculateNumberOfFavouriteEvents();
    }
  },

  onPunterSessionUpdated: (data) => {
    window.punterToken = data.punterToken;
    store.punterLoggedIn = !!window.punterToken;

    if (punterToken) {
      establishBetslipDistributionSocketConnection(window.punter);
      establishCashoutSocketConnection(window.punter);
      if (window.manualWalletsUpdate) autoReloadWallets();
    } else disconnectFromBetslipDistributionSocketConnection();

    if (shouldLoadPunterPreferences) {
      shouldLoadPunterPreferences = false;
      applyPunterPreferences();

      emitter.emit('punter-preferences-changed');
    }
  },

  onPunterSessionDestroyed: () => {
    window.punter = null;
    window.punterToken = null;

    store.punterLoggedIn = false;
    store.punterData = null;
  },

  onPunterWalletsUpdated: (data) => {
    console.log('[AIO Log] Punter.Wallets message received from Integrator:', data);

    if (data?.activeCurrencyBalance) {
      store.updatePunterWallet({
        enabled: true,
        activeCurrencyBalance: data.activeCurrencyBalance,
      });
    }
  },
});

emitter.on('require-login', () => integrator && integrator.requireLogin());

emitter.on('fetch-wallet', () => integrator && window.manualWalletsUpdate && loadWallets());

async function loadWallets() {
  try {
    const wallets = await integrator.getWallets();
    store.updatePunterWallet({
      enabled: true,
      activeCurrencyBalance: wallets?.activeCurrencyBalance,
    });
  } catch (error) {
    console.log('Error fetching wallets.', error);
  }
}

let autoReloadWalletsInterval = null;
function autoReloadWallets() {
  clearInterval(autoReloadWalletsInterval);
  autoReloadWalletsInterval = setInterval(loadWallets, 60000);
}

watch(game, async (newVal) => {
  resetLocalState();
  store.resetCompleteOfferState();
  initialLoad = true;
  window.scrollTo({ top: 0, behavior: 'instant' });

  const metaSelected =
    routeParams.value.sport ||
    routeParams.value.category ||
    routeParams.value.tournament ||
    routeParams.value.promotedOffer ||
    routeParams.value.catalog;

  if (newVal === 'LIVE')
    setSelectedFiltersExternal({
      ...store.selectedFiltersExternal,
      id: metaSelected ? '' : 'all',
      time: 'all',
      sport: routeParams.value.sport ?? null,
      category: routeParams.value.category ?? null,
      tournament: routeParams.value.tournament ?? null,
      promotedOffer: routeParams.value.promotedOffer ?? null,
      catalog: routeParams.value.catalog ?? null,
      favourites: false,
      streamedEvents: false,
      sortBy: 'byTournament',
    });
  else
    setSelectedFiltersExternal({
      offer: 'offer',
      sport: routeParams.value.sport ?? null,
      category: routeParams.value.category ?? null,
      tournament: routeParams.value.tournament ?? null,
      promotedOffer: routeParams.value.promotedOffer ?? null,
      catalog: routeParams.value.catalog ?? null,
      time: routeParams.value.time ?? store.config?.defaultRoute.prematch.time ?? 'all',
      favourites: false,
      sortBy: 'byTournament',
    });

  if (routeParams.value.view) {
    store.setSelectedView(routeParams.value.view);
  }

  store.setSearchTerm('');

  loadInitialAppData();
});

const selectionsToWatch = computed(() =>
  betslipStore.selectionsList.reduce((selectionArr, selectionId) => {
    const selection = betslipStore.selectionsData[selectionId];
    return selection.active
      ? [...selectionArr, { odds: selection.odds, numOfMarkets: selection.markets.size }]
      : selectionArr;
  }, []),
);

watch(
  [selectionsToWatch, toRef(() => betslipStore.stake)],
  ([newSelections, newStake], [oldSelections, oldStake]) => {
    betslipStore.requestBetCalculation();

    if (!newSelections.length && !oldSelections.length) return;
    if (!newSelections.length) {
      removeItemFromStorage('user-bet');
      return;
    }

    if (JSON.stringify(newSelections) !== JSON.stringify(oldSelections) || newStake !== oldStake)
      betslipStore.storeBetslipDataInLS();
  },
  { deep: true },
);

const routeParams = computed(() => store.routeParams);
watch(routeParams, (params, oldParams) => {
  const areFiltersDifferent =
    params.sport !== oldParams.sport ||
    params.category !== oldParams.category ||
    params.tournament !== oldParams.tournament ||
    params.promotedOffer !== oldParams.promotedOffer ||
    params.catalog !== oldParams.catalog ||
    params.time !== oldParams.time ||
    params.favourites !== oldParams.favourites ||
    params.streamedEvents !== oldParams.streamedEvents;

  // handle filter changes
  if (areFiltersDifferent) {
    setSelectedFiltersExternal({
      ...store.selectedFiltersExternal,
      ...params,
      time: params.time ?? Object.values(store.timeFilters)[0].id,
      sport:
        params.sport ??
        (!store.isLive &&
        !params.category &&
        !params.tournament &&
        !params.favourites &&
        !params.streamedEvents &&
        !params.promotedOffer &&
        !params.catalog
          ? orderBy(
              store.filterSports,
              game.value === 'LIVE' ? 'positionLive' : 'positionPrematch',
            )?.[0]?.id
          : undefined),
      category: params.category,
      tournament: params.tournament,
      promotedOffer:
        params.promotedOffer ??
        (params.catalog
          ? store.promoFilterOffers[store.promoFilterCatalogs[params.catalog]?.promotedOfferId]
          : null),
      catalog: params.catalog,
      favourites: params.favourites ?? null,
      streamedEvents: params.streamedEvents ?? null,
      id:
        isLive.value &&
        !params.sport &&
        !params.favourites &&
        !params.streamedEvents &&
        !params.promotedOffer &&
        !params.catalog
          ? 'all'
          : null,
    });
  }

  // handle eventview changes
  if (params.event && oldParams.event !== params.event) {
    store.eventviewId = +params.event;
  } else if (!params.event && oldParams.event) {
    store.eventviewId = null;
  }

  // handle expanded category or sport on menu
  if (!params.tournament && !params.category && (oldParams.tournament || oldParams.category)) {
    store.expandedSport = null;
  }

  if (!params.tournament && oldParams.tournament) {
    store.expandedCategory = null;
  }

  if (isMobile) {
    if (params.view) {
      store.setSelectedView(params.view);
    } else {
      store.setSelectedView(isLive.value ? 'live' : 'sport');
    }
  }

  nextTick(() => {
    if (!initialLoad && areFiltersDifferent && params.view !== 'search') {
      if (params.time !== oldParams.time) {
        loadCursorOfferStats();

        if (!params.favourites) {
          fetchAndCalculateNumberOfFavouriteEvents();
        }
      }
      loadAndSubscribeOfferDataByFilters();
      return;
    }

    if (game.value === 'SPORTS' && oldParams.view === 'search') {
      loadAndSubscribeOfferDataByFilters();
    }
  });

  // Emit SDK function to notify parent app about route change
  try {
    if (integrator) integrator.navigationChanged({ path: window.location.pathname || '' });
  } catch (e) {
    // Do nothing.
  }
});

const AppMobile = defineAsyncComponent({
  loader: () => import('@/components/mobile/Mobile.vue'),
});

const AppDesktop = defineAsyncComponent({
  loader: () => import('@/components/desktop/Desktop.vue'),
});

onBeforeUnmount(() => {
  clearInterval(autoReloadWalletsInterval);
});
</script>

<template>
  <component
    :is="isMobile ? AppMobile : AppDesktop"
    v-if="appReadyForLoad" />
</template>
