import { computed } from 'vue';
import { useStore } from '@/stores/store';
import { useBetslipStore } from '@/stores/BetslipStore';
import { getTicketType } from '@/services/bet-formatter';
import { formatNumber, formatOdd } from '@/utils/helpers';

const errorCodes = [1001, 1004];

export default function useBetDetails(bet) {
  const store = useStore();
  const betslipStore = useBetslipStore();
  const lowerOddsStatuses = ['VOID', 'HALF_WIN', 'HALF_LOST'];

  const betEvents = computed(() => {
    return bet.value?.selections?.reduce((acc, selection) => {
      if (acc.includes(selection.event.syntheticId)) return acc;
      acc.push(+selection.event.syntheticId);
      return acc;
    }, []);
  });

  const betDetailsSelections = computed(() => store.betDetailsSelections);

  const totalOdds = computed(() => {
    if (isSystemBet.value) return 0;

    const decimalOdds = bet.value.selections
      .reduce(
        (acc, { odds, resolutionStatuses }) =>
          acc * getDisplayOdds({ odds, resolutionStatuses, format: 'decimal' }),
        1,
      )
      .toFixed(2);

    return formatOdd(decimalOdds, store.oddFormat, 1);
  });

  const totalCombinations = computed(() => {
    if (!isSystemBet.value) return null;

    return systems.value.reduce(
      (acc, { events, parlays }) => acc + betslipStore?.pascalTriagle?.[events]?.[parlays],
      0,
    );
  });

  const maxWinnings = computed(() =>
    bet.value.winnings.find((winnings) => winnings.type === 'MAX'),
  );
  const minWinnings = computed(() =>
    bet.value.winnings.find((winnings) => winnings.type === 'MIN'),
  );

  const settleWinnings = computed(() =>
    bet.value.winnings.find((winnings) => winnings.type === 'SETTLE'),
  );

  const winningsToDisplay = computed(() => {
    return settleWinnings.value ?? maxWinnings.value;
  });

  const paidOutAmount = computed(() => {
    return isPaidOut.value ? settleWinnings.value?.total : 0;
  });

  const bonusAmount = computed(() => {
    return {
      winnings: maxWinnings.value.bonuses?.reduce((acc, { amount }) => acc + amount, 0) ?? 0,
      payin: bet.value.payin.bonuses?.reduce((acc, { amount }) => acc + amount, 0) ?? 0,
    };
  });

  const isSystemBet = computed(() => betType.value?.id === 2);

  const systems = computed(() => {
    if (!isSystemBet.value) return null;

    const bankersCount = bet.value.selections.filter(({ banker }) => banker).length;
    const nonBankersCount = bet.value.selections.length - bankersCount;

    return bet.value.selectedSystems.map((system) => ({
      bankers: bankersCount,
      events: nonBankersCount,
      parlays: system - bankersCount,
    }));
  });

  const isPaidOut = computed(() => {
    return bet.value.phase === 'PAID_OUT';
  });

  const paidOutStatus = computed(() => {
    return bet.value.cashouted ? 'status_cashouted' : 'status_paid_out';
  });

  const payoutLabel = computed(() => {
    const settled =
      isPaidOut.value ||
      aggregatedStatus.value === 'LOST' ||
      aggregatedStatus.value === 'CASHOUTED' ||
      aggregatedStatus.value === 'CASHBACKED';

    return store.getTranslation(settled ? 'payout' : 'possible_payout');
  });

  const aggregatedStatus = computed(() => bet.value.aggregatedStatus);

  const aggregatedStatusLabel = computed(() =>
    store.getTranslation(`status_${aggregatedStatus.value?.toLowerCase()}`),
  );

  const betStatus = computed(() =>
    isPaidOut.value ? paidOutStatus.value : aggregatedStatusLabel.value,
  );

  const betStatusAndPayout = computed(() => {
    return paidOutAmount.value
      ? `${store.getTranslation(paidOutStatus.value)}: ${formatNumber(paidOutAmount.value, bet.value.currency)}`
      : `${betStatus.value?.toUpperCase()}`;
  });

  const betPlacedAt = computed(() => {
    const placedAt = bet.value.datetimes.find(({ phase }) => phase === 'PREPARED');

    return formatDate(placedAt.value);
  });

  const systemLabel = computed(() => {
    if (!isSystemBet.value || !systems.value) return '';

    return systems.value
      .map(({ events, parlays, bankers }) => {
        const banker = bankers ? `${bankers}${store.getTranslation('B')} + ` : '';
        return `${banker}${parlays}/${events}`;
      })
      .join(', ');
  });

  const betType = computed(() => {
    return getTicketType({
      selectedSystems: bet.value.selectedSystems,
      selections: bet.value.selections,
    });
  });

  const betDetailsTitle = computed(
    () =>
      `${getGameTypeTranslation(bet.value.game)} ${store.getTranslation(
        bet.value.selections.some((selection) => selection.type === 'CUSTOM')
          ? 'bet_builder'
          : betType.value?.key,
      )} ${systemLabel.value}`,
  );

  // TODO: Once we get proper API, check if there is any other relevant Cancellable condition.
  const isBetCancellable = computed(() => false);

  const betId = computed(() => {
    const hash = bet.value.hashes.find(({ type }) => type === 'REGULAR');

    return hash?.value ?? '';
  });

  const formatDate = (date) => dayjs(date).format('DD.MM.YY HH:mm:ss');
  const formatSelectionDate = (date) => dayjs(date).format('DD.MM HH:mm');

  const getGameTypeTranslation = (gameType) => {
    const keys = {
      PREMATCH: 'prematch',
      LIVE: 'live_betting',
      MIX: 'mix',
    };

    return store.getTranslation(keys[gameType]);
  };

  const getSelectionResolutionStatus = (resolution) => {
    const settle = resolution.find(({ selectionState }) => selectionState === 'SETTLE')?.value;
    const inPlay = resolution.find(({ selectionState }) => selectionState === 'IN_PLAY')?.value;
    const open = resolution.find(({ selectionState }) => selectionState === 'OPEN').value;

    return settle || inPlay || open;
  };

  const getSelectionResolutionStatusLabel = (resolution) =>
    store.getTranslation(`status_${getSelectionResolutionStatus(resolution)?.toLowerCase()}`);

  const shouldShowSettleOdds = (resolution) => {
    const selectionStatus = getSelectionResolutionStatus(resolution);

    return lowerOddsStatuses.includes(selectionStatus);
  };

  const getDisplayOdds = ({ odds, resolutionStatuses, format }) => {
    const settleOdds = odds.find(({ type }) => type === 'SETTLE_ODDS');
    const requestedOdds = odds.find(({ type }) => type === 'REQUESTED_ODDS');

    const status = getSelectionResolutionStatus(resolutionStatuses);
    const displaySettleOdds = lowerOddsStatuses.includes(status) ? settleOdds : null;
    const oddsToDisplay = displaySettleOdds ?? requestedOdds ?? odds[0];

    return formatOdd(oddsToDisplay.value.toFixed(2), format ?? store.oddFormat, 1);
  };

  const getRequestedOdds = (odds, format = null) => {
    const requestedOdds = odds.find(({ type }) => type === 'REQUESTED_ODDS');

    return formatOdd((requestedOdds ?? odds[0]).value.toFixed(2), format ?? store.oddFormat, 1);
  };

  const handleClipboardCopy = () => {
    store.addNotification({
      timeout: 2000,
      text: store.getTranslation('generated_code_copied_success'),
      type: 'success',
    });
  };

  const handleCashout = (betProfileId, cashout, entryPoint) => {
    store.cashOutBet({ betProfileId, cashout, id: bet.value.id, currency: bet.value.currency });

    window.pushEventToGoogleTagManager('cashout', {
      entry_point: entryPoint,
    });
  };

  const updateBetStatus = (status) => {
    bet.value.aggregatedStatus = status;
  };

  const cashoutIncludesErrorCode = computed(() => {
    const { cashout } = bet.value;

    if (!cashout) return false;

    return cashout.codes.some((code) => errorCodes.includes(Number(code)));
  });

  return {
    totalOdds,
    winningsToDisplay,
    isPaidOut,
    maxWinnings,
    minWinnings,
    isSystemBet,
    aggregatedStatus,
    betStatusAndPayout,
    betPlacedAt,
    isBetCancellable,
    betDetailsTitle,
    systemLabel,
    betEvents,
    betDetailsSelections,
    systems,
    totalCombinations,
    bonusAmount,
    payoutLabel,
    betId,
    handleClipboardCopy,
    handleCashout,
    formatDate,
    formatSelectionDate,
    getSelectionResolutionStatus,
    getSelectionResolutionStatusLabel,
    getDisplayOdds,
    getRequestedOdds,
    shouldShowSettleOdds,
    updateBetStatus,
    cashoutIncludesErrorCode,
  };
}
